<script setup lang="ts">
import type { ButtonGroupItemProps } from './types'
import { tv } from 'tailwind-variants'

// OPTIONS
defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<ButtonGroupItemProps>(), {
  name: undefined,
  tooltip: undefined,
  disabled: false,
  to: undefined,
  icon: undefined,
  exact: undefined,
  shape: 'rounded',
})

// EMITS
const emit = defineEmits<{ (e: 'click', event: Event): void }>()

// ACTIVE
const attrs = useAttrs()
const active = computed(() => attrs['data-active'] as boolean)

const disabled = computed(() => props.disabled || props.to === null)

const onClick = (event: MouseEvent) => {
  if (event.shiftKey || event.ctrlKey || event.metaKey) return
  emit('click', event)
}

// VARIANT
const shape = computed(() => props.shape)
const button = tv({
  slots: {
    button:
      'flex flex-col items-center justify-center bg-transparent px-3 py-1',
    buttonInner: 'text-md flex items-center',
    icon: 'mr-1 flex self-center',
    tooltip: undefined,
  },
  variants: {
    disabled: {
      true: {
        button: 'pointer-events-none opacity-40 hover:bg-transparent',
      },
      false: undefined,
    },
    active: {
      true: undefined,
      false: {
        buttonInner: 'text-gray-100',
      },
    },
    shape: {
      rounded: undefined,
      square: undefined,
    },
  },
  compoundVariants: [
    /* Rounded */
    {
      shape: 'rounded',
      class: {
        button: 'rounded-xl border border-transparent',
      },
    },
    {
      shape: 'rounded',
      disabled: false,
      class: {
        button:
          'outline-none hover:bg-gray-750 focus:bg-gray-750 focus:ring-gray-550/80 focus:ring',
      },
    },
    {
      shape: 'rounded',
      active: true,
      class: {
        button:
          'border-gray-750 bg-gray-800 hover:bg-gray-800 focus:bg-gray-800',
        buttonInner: 'text-primary',
      },
    },
    /* Square */
    {
      shape: 'square',
      class: {
        button: [
          'rounded-none',
          'outline-none',
          'border-b-2',
          'border-b-transparent',
          'hover:bg-transparent',
          'focus:bg-transparent',
          'focus:appearance-none',
        ],
      },
    },
    {
      shape: 'square',
      disabled: false,
      active: false,
      class: {
        button: [
          'hover:border-b-primary',
          'focus:border-b-gray-550',
          'hover:focus:border-b-primary',
        ],
      },
    },
    {
      shape: 'square',
      active: true,
      class: {
        button: [
          'border-b-2',
          'border-b-primary',
          'hover:border-b-primary',
          'focus:border-b-primary',
        ],
      },
    },
  ],
})

const variant = button({
  get disabled() {
    return disabled.value
  },
  get active() {
    return active.value
  },
  get shape() {
    return shape.value
  },
})
</script>

<template>
  <Tooltip
    :content="disabled ? undefined : tooltip?.content"
    :placement="tooltip?.placement"
    :class="variant.tooltip({ class: tooltip?.class })"
    role="tooltip"
    :aria-describedby="disabled ? undefined : tooltip?.content"
  >
    <Button
      :class="variant.button({ class: $attrs.class, active, disabled })"
      :disabled="disabled"
      :to="to ?? undefined"
      @click="onClick"
    >
      <div :class="variant.buttonInner({ active, disabled })">
        <Icon
          v-if="icon"
          :name="icon"
          filled
          :class="variant.icon({ active, disabled })"
        />

        <slot />
      </div>
    </Button>
  </Tooltip>
</template>
